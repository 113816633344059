import React from 'react'
import { lazy, Suspense } from 'react'
import '../scss/component/_section.scss'
import '../scss/component/_box.scss'
import '../scss/component/_tf-section.scss'
import '../scss/component/_reponsive.scss'
import dataBanner from '../assets/fake-data/data-banner'
import Project from '../components/project/project_v1'
import dataProject from '../assets/fake-data/data-project'
// import Project4 from '../components/project/project_v4'
import Metodology from '../components/project/metodology'
import Roadmap from '../components/vertical-roadmap'
import { useTranslation } from 'react-i18next'

// import Team from '../components/team'
import Potential from '../components/partner/potential'
import dataPotential from '../assets/fake-data/data-potentials'
import dataConnections from '../assets/fake-data/data-connections'
import CTA from '../components/cta'
// import dataTeam from '../assets/fake-data/data-team'
import dataMedia from '../assets/fake-data/data-media.js'

import VideoPlayer from '../components/video-player/'
import TwoVideoPlayers from '../components/video-player/TwoVideoPlayers'
// import Counter from '../components/counter'
import InMedia from '../components/partner/inMedia'
import Connections from '../components/partner/connections.jsx'
import dataRoadmap from '../assets/fake-data/data-roadmap.js'

import { Helmet } from 'react-helmet';

function HomeOne(props) {
  const { t } = useTranslation()
  const Banner = lazy(() => import('../components/banner/banner_v1'))
  return (
    <>
    <Helmet>
    <meta name="description" content="Educação inovadora para transformar seu futuro! Desenvolva habilidades essenciais para o mercado global com experiências de aprendizagem modernas e eficazes." />
    <meta name="keywords" content="Educação inovadora, Desenvolvimento de habilidades, Aprendizagem revolucionária, Competências para o mercado global, Formação profissional moderna, Educação para o futuro, Capacitação profissional, Habilidades do século XXI, Educação e tecnologia, Aprendizagem contínua" />
    <meta property="og:title" content="Edufuturo - Despertar uma nova consciência no mundo" />
    <meta property="og:description" content="Transforme seu futuro com uma educação inovadora! Desenvolva habilidades essenciais para o mercado global com experiências de aprendizagem modernas e eficazes." />
    <meta property="og:image" content="https://edufuturo.com.br/static/media/logo.df71cf09ceacc9c567b1.png" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.edufuturo.com.br/" />
  </Helmet>
    <div className='header-fixed main home1 counter-scroll'>
      {
        <Suspense>
          <Banner data={dataBanner} button_url={props.linkRegister} i18n={t} />
        </Suspense>
      }
      {
        <Project
          title='How To Prepare For The Unexpected?'
          data={dataProject}
          button_url={props.linkRegister}
          i18n={t}
          desc="Today's young people must be prepared to thrive in an ever-evolving technology landscape. Developing valuable life skills, revolutionary learning experiences that are sought after in today's job market around the world, align BUSSINESS PRO, REAL SKILLS, HARD SKILLS and in fact, should encompass the concept of CORE SKILLS, neither Hard nor Soft, a mix everything."
        />
      }
      <VideoPlayer
        title='A school that surprises at every opportunity'
        subtitle='Schools must focus on being a “Complete School”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers really ready for lifes challenges.'
        url='https://youtu.be/N2M71T0ggWo'
        button_url={props.linkRegister}
        i18n={t}
      />
      <TwoVideoPlayers
        firstSubTitle='Um lugar para você ser você mesmo!'
        secondSubTitle='Semana de Celebração de Aprendizado 2025.'
        urlFirstVideo='https://youtu.be/QkvWLzV1Pyw'
        urlSecondVideo='https://youtu.be/umS5AaI7oXU'
        button_url={props.linkRegister}
        i18n={t}
      />

      {
        <Metodology
          data={dataProject}
          title='American Teaching Methodology'
          subtitle='Schools must focus on being “complete schools”, on real transformation, they must be schools that surprise at every opportunity. Children and teenagers truly ready for lifes challenges.'
          button_url={props.linkRegister}
          i18n={t}
        />
      }
      <VideoPlayer
        url='https://youtu.be/UeGvHxA7wE4'
        title='Get to know Edufuturo'
        subtitle='At Edufuturo, we create a future where education transforms lives and creates human solutions through technology. We are more than a school; we are an innovation hub that connects children and teenagers to a universe of possibilities.'
        i18n={t}
        button_url={props.linkRegister}
      />

      <Roadmap
        data={dataRoadmap}
        title='How Edufuturo Works in Practice'
        subtitle='Edufuturo combines innovation and methodology to offer a unique educational experience, designed to prepare children and adolescents for the challenges of the future. Our operation is based on three main pillars: environment, methodology and purpose.'
      />
      <Potential data={dataPotential} />

      <InMedia data={dataMedia} />

      <Connections data={dataConnections} />

      <CTA
        button_url={props.linkRegister}
        title='Make a register!'
        subtitle='Enroll your child now and be part of this transformation for the future.'
        button_title='Make a register!'
      />
    </div>
    </>
  )
}

export default HomeOne
