import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import AOS from 'aos';
import routes from './pages';
import '../src/assets/fonts/font-awesome.css';
import Header from './components/header';
import Footer from './components/footer';
import br from './translations/pt-br';
import LazyHydrate from 'react-lazy-hydration';
import TurnstileComponent from './components/turnstile';
import * as Sentry from '@sentry/react';

import TagManager from 'react-gtm-module';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const configureI18n = () => {
  const storedLanguage = localStorage.getItem('language');
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        br: br,
      },
      lng: storedLanguage || 'br', // fallback para 'br' caso o item não exista
      fallbackLng: 'br',
      interpolation: {
        escapeValue: false, // react já se protege contra xss
      },
    });
};

configureI18n();

const tagManagerArgs = {
  gtmId: 'GTM-NXMCWTZN',
};
TagManager.initialize(tagManagerArgs);

function App() {
  const sentryDSN = process.env.sentryDSN

  // Verificar se o dataLayer já está definido
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
      });
    }
    // Inicializa o Sentry quando o DSN for obtido
    Sentry.init({
      dsn: sentryDSN,
      environment: 'production', // Defina o ambiente
      tracesSampleRate: 1.0, // Captura 100% das transações (ajuste conforme necessário)
      integrations: [Sentry.browserTracingIntegration()],
      tracePropagationTargets: [
        "localhost", 
        "https://edufuturo.com.br", 
        /^https:\/\/edufuturo\.com\.br/
      ],
    });
  }, [sentryDSN]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <Sentry.ErrorBoundary fallback={<p>Something went wrong</p>}>
        <LazyHydrate whenIdle>
          <TurnstileComponent />
          <Header />
          <Routes>
            {routes.map((data, idx) => (
              <Route key={idx} path={data.path} element={data.component} />
            ))}
          </Routes>
          <Footer />
        </LazyHydrate>
      </Sentry.ErrorBoundary>
    </>
  );
}

export default App;
