import { useEffect, useState } from "react";

const TurnstileInvisible = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Carregar o script do Turnstile dinamicamente
    const script = document.createElement("script");
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.async = true;
    script.onload = () => {
      console.log("Turnstile script carregado");
      renderTurnstile();
    };
    document.body.appendChild(script);

    const renderTurnstile = () => {
      if (window.turnstile) {
        window.turnstile.render("#turnstile-container", {
          sitekey: "0x4AAAAAABCu9xNH1SE8stBe",
          size: "invisible",
          callback: (token) => {
            console.log("Token recebido:", token);
            setToken(token);
            enviarTokenParaWorker(token);
          },
        });
      } else {
        console.error("Turnstile não carregado corretamente.");
      }
    };

    const enviarTokenParaWorker = (token) => {
      fetch("https://edufuturo.com.br/turnstile", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ "cf-turnstile-response": token }),
      })
        .then((res) => {
          // Verificar se a resposta é válida
          if (!res.ok) {
            throw new Error(`Erro: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => console.log("Resposta do Worker:", data))
        .catch((err) => console.error("Erro ao enviar token:", err));
    };

  }, []);

  return <div id="turnstile-container"></div>;
};

export default TurnstileInvisible;
