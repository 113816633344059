import React from 'react'

import icon from '../../assets/images/common/down.svg'

import { useTranslation } from 'react-i18next'

function Roadmap(props) {
  const { t } = useTranslation()

  return (
    <div className=''>
      <section className='tf-section roadmap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='tf-title head-roadmap'
                data-aos='fade-up'
                data-aos-duration='800'
              >
                <h2 className='title'>
                  {t(props.title)}
                  <br className='show-destop' />
                </h2>
                <p className='sub'>{t(props.subtitle)}</p>
              </div>
              <div
                className='roadmap-wrapper-style2'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                <div className='left'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Transformative Environment')}
                      </h6>
                      <p>
                        {t(
                          'Our Future Rooms are innovative spaces that integrate technology, design and interactivity. With features such as digital screens, artificial intelligence, robotics and gamification, we create an immersive environment that inspires creativity and collaborative learning. Each space is designed to foster curiosity, engagement and innovation.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Purpose Aligned with the Future')}
                      </h6>
                      <p>
                        {t(
                          'Each stage of learning is designed to develop technical skills (Hard Skills), emotional skills (Soft Skills) and, most importantly, Core Skills – a fusion of essential competencies for life and the job market. We celebrate achievements through special events, where students present their projects and reaffirm their commitment to innovation, continuous learning and positive impact on society.',
                        )}
                      </p>
                    </div>
                  </div>
                  {/* <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('The Follow-up')}</h6>
                      <p>
                        {t(
                          "Parents and guardians will be able to monitor the student's development through reports that will be sent explaining their progress in detail and also through our online platforms.",
                        )}
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className='right'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Active and Immersive Methodology')}
                      </h6>
                      <p>
                        {t(
                          'We adopt a Project-Based Learning (PBL) approach, where students, called Changemakers, solve real-world problems while learning. They work on hands-on projects that explore areas such as Science and Technology, Entrepreneurship, Art and Design, Music, Media, Film and Games. Teaching is bilingual or entirely in English, and our students have access to international experiences, global certifications and exchanges, connecting them to the global market and world cultures, as well as exclusive access to a gamified platform.',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t(
                          'In practice, Edufuturo is more than teaching – it is inspiring.',
                        )}
                      </h6>
                      <p>
                        {t(
                          'At the end of each course, students will have to create a project and present it to the class. The project will also be documented and submitted on the platform following the methodology standards.',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Roadmap
