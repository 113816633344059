import React from 'react'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import CTA from '../components/cta'
import icon from '../assets/images/common/down.svg'
import { useTranslation } from 'react-i18next'

function Trails(props) {
  const { t } = useTranslation()

  return (
    <div className='inner-page'>
      {/* Helmet para SEO */}
      <Helmet>
        <title>{t('Trails - Edufuturo')}</title>
        <meta name="description" content={t('Unleash Your Potential and Master Your Skills. Learning by playing on a platform with its own currency and engaging gamification process')} />
        <meta name="keywords" content="Educação inovadora, Desenvolvimento de habilidades, Gamificação, Competências para o mercado, Jogos educativos, React.js, Google" />
        <meta property="og:title" content={t('Trails - Edufuturo')} />
        <meta property="og:description" content={t('Unleash Your Potential and Master Your Skills. Learning by playing on a platform with its own currency and engaging gamification process')} />
        <meta property="og:image" content="https://edufuturo.com.br/static/media/logo.df71cf09ceacc9c567b1.png" />
        <meta property="og:url" content="https://www.edufuturo.com.br/trails" />
      </Helmet>

      {/* Hero Section */}
      <Hero
        title='Trails'
        desc='Unleash Your Potential And Master Your Skills'
        sub='Learning by playing on a platform with its own currency and engaging gamification process'
        i18n={t}
      />

      {/* Roadmap Section */}
      <section className='tf-section roadmap'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div
                className='roadmap-wrapper-style2'
                data-aos='fade-up'
                data-aos-duration='1200'
              >
                <div className='left'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>
                        {t('Business + Entrepreneurship')}
                      </h6>
                      <p>
                        {t(
                          'Entrepreneurs are made, not born. This course was developed and designed in coordination with business school educational developers, industry consultants, and students.',
                        )}
                      </p>
                    </div>
                  </div>
                  {/* Outros itens do roadmap */}
                </div>
                <div className='right'>
                  <div className='roadmap-box-style active'>
                    <div className='icon'>
                      <img src={icon} alt='' />
                    </div>
                    <div className='content'>
                      <h6 className='date'>{t('Robotics + Engineering')}</h6>
                      <p>
                        {t(
                          'Few things are more rewarding than building something for yourself. Throughout the course, students will learn how to build and program robots using Arduino.',
                        )}
                      </p>
                    </div>
                  </div>
                  {/* Outros itens do roadmap */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <CTA
        title={t('Make a register!')}
        subtitle={t('Enroll your child now and be part of this transformation for the future.')}
        button_title={t('Make a register!')}
      />
    </div>
  )
}

export default Trails
